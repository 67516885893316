// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #072f40;
  color: #666;
  font-size: 0.8rem;
  border-top: 1px solid #333; /* Include border here */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem; /* Add margin to separate from footer info */
  margin-top: 1rem;
}

.footer-links a {
  color: #666;
  text-decoration: none;
  font-size: 0.8rem;
}

.footer-links a:hover {
  color: #007bff;
}

.social-links {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.social-links a {
  color: #666;
  transition: color 0.2s ease;
}

.social-links a:hover {
  color: #007bff;
}

.footer-info {
  text-align: center;
}

.footer-info p {
  margin: 0.25rem 0;
  color: #666;
  font-size: 0.75rem;
}

@media (max-width: 768px) {
    .footer-links{
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,iBAAiB;EACjB,0BAA0B,EAAE,wBAAwB;EACpD,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB,EAAE,4CAA4C;EACjE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,kBAAkB;AACpB;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;QACnB,WAAW;IACf;AACJ","sourcesContent":[".footer {\n  background-color: #072f40;\n  color: #666;\n  font-size: 0.8rem;\n  border-top: 1px solid #333; /* Include border here */\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: auto;\n}\n\n.footer-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.footer-links {\n  display: flex;\n  gap: 1.5rem;\n  margin-bottom: 1rem; /* Add margin to separate from footer info */\n  margin-top: 1rem;\n}\n\n.footer-links a {\n  color: #666;\n  text-decoration: none;\n  font-size: 0.8rem;\n}\n\n.footer-links a:hover {\n  color: #007bff;\n}\n\n.social-links {\n  display: flex;\n  gap: 1rem;\n  margin-bottom: 1rem;\n}\n\n.social-links a {\n  color: #666;\n  transition: color 0.2s ease;\n}\n\n.social-links a:hover {\n  color: #007bff;\n}\n\n.footer-info {\n  text-align: center;\n}\n\n.footer-info p {\n  margin: 0.25rem 0;\n  color: #666;\n  font-size: 0.75rem;\n}\n\n@media (max-width: 768px) {\n    .footer-links{\n        flex-direction: column;\n        align-items: center;\n        gap: 0.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
