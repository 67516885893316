// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recaptcha-checkbox {
    align-items: center;
    margin: 10px 0;
}

.recaptcha-checkbox input[type="checkbox"] {
    justify-content: center;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    appearance: none; /* Remove default checkbox styling */
    border: 2px solid var(--primary-color); /* Use primary color for border */
    border-radius: 4px; /* Rounded corners */
    position: relative;
    cursor: pointer;
}

.recaptcha-checkbox input[type="checkbox"]:checked::before {
    content: '✔';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: var(--primary-color); /* Use primary color for checkmark */
}

.recaptcha-checkbox label {
    font-size: 16px;
    color: #333;
    cursor: pointer;
    line-height: 10px; /* Match the height of the checkbox */
}
`, "",{"version":3,"sources":["webpack://./src/components/Recaptcha/Recaptcha.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gBAAgB,EAAE,oCAAoC;IACtD,sCAAsC,EAAE,iCAAiC;IACzE,kBAAkB,EAAE,oBAAoB;IACxC,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,eAAe;IACf,2BAA2B,EAAE,oCAAoC;AACrE;;AAEA;IACI,eAAe;IACf,WAAW;IACX,eAAe;IACf,iBAAiB,EAAE,qCAAqC;AAC5D","sourcesContent":[".recaptcha-checkbox {\n    align-items: center;\n    margin: 10px 0;\n}\n\n.recaptcha-checkbox input[type=\"checkbox\"] {\n    justify-content: center;\n    margin-right: 10px;\n    width: 20px;\n    height: 20px;\n    appearance: none; /* Remove default checkbox styling */\n    border: 2px solid var(--primary-color); /* Use primary color for border */\n    border-radius: 4px; /* Rounded corners */\n    position: relative;\n    cursor: pointer;\n}\n\n.recaptcha-checkbox input[type=\"checkbox\"]:checked::before {\n    content: '✔';\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-size: 16px;\n    color: var(--primary-color); /* Use primary color for checkmark */\n}\n\n.recaptcha-checkbox label {\n    font-size: 16px;\n    color: #333;\n    cursor: pointer;\n    line-height: 10px; /* Match the height of the checkbox */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
