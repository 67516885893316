class Company {
    constructor(name, ticker, country, sector, subsector, description, exchange, lastUpdated, isActive = true) {
        this.name = name;
        this.ticker = ticker;
        this.country = country;
        this.sector = sector;
        this.subsector = subsector;
        this.description = description;
        this.exchange = exchange;
        this.lastUpdated = lastUpdated;
        this.isActive = isActive;
    }

    static fromJSON(data) {
        return new Company(
            data.name,
            data.ticker,
            data.country,
            data.sector,
            data.subsector,
            data.description,
            data.exchange,
            new Date(data.last_updated),
            data.is_active
        );
    }

    toJSON() {
        return {
            name: this.name,
            ticker: this.ticker,
            country: this.country,
            sector: this.sector,
            subsector: this.subsector,
            description: this.description,
            exchange: this.exchange,
            last_updated: this.lastUpdated.toISOString(),
            is_active: this.isActive
        };
    }
}

export default Company;