import React, { createContext, useState, useEffect } from 'react';
import { axiosInstance, axiosInstanceWithPolling } from '../axios';
import Loading from '../components/Loading/Loading';

export const NewsContext = createContext();

export const NewsProvider = ({ children }) => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                const response = await axiosInstanceWithPolling('/news/bulletin', {}, 1000);
                setNews(response.data);
            } catch (error) {
                console.error('Error fetching news data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNewsData();
    }, []);

    if (loading) <Loading />;

    return (
        <NewsContext.Provider value={news}>
            {children}
        </NewsContext.Provider>
    );
};