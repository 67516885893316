// Chart.js
import React, { useEffect, useState } from 'react';
import './Chart.css';
import TreeMap from '../TreeMap/TreeMap';
import PriceChart from '../Price/PriceChart'; // Assuming you have a Price component
import UnderConstruction from '../Construction/UnderConstruction';
import Fundamentals from '../Fundamentals/Fundamentals';
import NewsBulletin from '../News/NewsBulletin';
import ChartHeader from './ChartHeader';
import Loading from '../Loading/Loading';
import CompanyNews from '../News/CompanyNews';

const Chart = ({ chartType, filters }) => {
    const [loading, setLoading] = useState(true);
    const [priceChartLoading, setPriceChartLoading] = useState(true);
    const [fundamentalsLoading, setFundamentalsLoading] = useState(true);
    const [companyNewsLoading, setCompanyNewsLoading] = useState(true);

    useEffect(() => {
        if (!priceChartLoading && !fundamentalsLoading && !companyNewsLoading) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [priceChartLoading, fundamentalsLoading, companyNewsLoading]);

    const renderChart = () => {
        switch (chartType) {
            case 'Tree Map':
                return <TreeMap filters={filters} />;
            case 'Stock':
                return (
                    <>
                        { loading && <Loading /> }
                        <div className={`scrollable-container ${loading ? 'hidden' : ''}`}>
                            <ChartHeader ticker={filters['Company']} companyName={filters['CompanyName']} />
                            <PriceChart ticker={filters['Company']} onLoadingChange={setPriceChartLoading} />
                            <Fundamentals ticker={filters['Company']} companyName={filters['CompanyName']} onLoadingChange={setFundamentalsLoading} />
                            <CompanyNews ticker={filters['Company']} companyName={filters['CompanyName']} onLoadingChange={setCompanyNewsLoading}/>
                        </div>
                    </>
                );
            case 'News':
                return <NewsBulletin companyName={filters['CompanyName']} source={filters['Source']} date={filters['Date']} />;
            default:
                return <div>Select a Chart Type</div>;
        }
    };

    return (
        <div className="chart-container">
            {renderChart()}
        </div>
    );
};

export default Chart;
