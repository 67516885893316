import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Recaptcha from '../../components/Recaptcha/Recaptcha'; // Adjust the import path as necessary
import './Advertise.css';

function Advertise() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        adContent: ''
    });

    const [errors, setErrors] = useState({
        name: false,
        email: false,
        adContent: false
    });

    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const [recaptchaError, setRecaptchaError] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: false
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {
            name: formData.name === '',
            email: formData.email === '',
            adContent: formData.adContent === ''
        };

        if (newErrors.name || newErrors.email || newErrors.adContent) {
            setErrors(newErrors);
            return;
        }

        if (!recaptchaVerified) {
            setRecaptchaError(true);
            return;
        }

        emailjs.send('service_g9lhl1v', 'template_pq4at4s', formData, 'O0xG6jlBxtc7V6Clh')
            .then((result) => {
                setFormSubmitted(true);
            }, (error) => {
                alert('Failed to send email.');
            });
    };

    const handleRecaptchaVerify = (verified) => {
        setRecaptchaVerified(verified);
        setRecaptchaError(!verified);
    };

    return (
        <div className="advertise-container">
            <h2>We Welcome Advertisement!</h2>
            <div className="ad-details">
                <h3>Why Advertise With Us?</h3>
                <ul>
                    <li><strong>Targeted Audience:</strong> Reach active traders and investors for maximum relevance.</li>
                    <li><strong>AI-Focused:</strong> Align your brand with cutting-edge AI tools and insights.</li>
                    <li><strong>High Visibility:</strong> Gain exposure in a fast-growing fintech community.</li>
                </ul>
            </div>
            {formSubmitted ? (
                <p className="success-message">Your advertisement has been successfully submitted!</p>
            ) : (
                <div className="ad-form">
                    <h3>Submit Your Advertisement</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Name:</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className={errors.name ? 'error' : ''}
                            />
                            {errors.name && <span className="error-message">Please enter your name</span>}
                        </div>
                        <div className="form-group">
                            <label>Email:</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className={errors.email ? 'error' : ''}
                            />
                            {errors.email && <span className="error-message">Please enter your email</span>}
                        </div>
                        <div className="form-group">
                            <label>Ad Content:</label>
                            <textarea
                                name="adContent"
                                value={formData.adContent}
                                onChange={handleChange}
                                className={errors.adContent ? 'error' : ''}
                            ></textarea>
                            {errors.adContent && <span className="error-message">Please enter ad content</span>}
                        </div>
                        <Recaptcha onVerify={handleRecaptchaVerify} />
                        {recaptchaError && <span className="error-message">Please verify that you are not a robot</span>}
                        <button type="submit" className="submit-button">Submit</button>
                    </form>
                </div>
            )}
        </div>
    );
}

export default Advertise;
