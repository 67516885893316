import React, { useState, useEffect } from "react";
import Select from "react-select";
import { axiosInstance } from "../../axios";
import { Company } from "../../models";

const SearchBar = ({ onTickerSelect }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchTickers = async () => {
            try {
                const response = await axiosInstance.get("/sp500/");
                const tickers = response.data.data.map((ticker) => ({
                    value: ticker.symbol,
                    label: ticker.name,
                }));
                setOptions(tickers);
            } catch (error) {
                console.error("Error fetching tickers:", error);
            }
        };

        fetchTickers();
    }, []);

    const handleSearchChange = (selectedOption) => {
        setSearchTerm(selectedOption ? selectedOption.label : "");
        onTickerSelect(selectedOption ? Company.fromJSON({ticker: selectedOption.value, name: selectedOption.label}) : undefined);
    }; 

    return (
        <div className="stock-search-bar">
            <label htmlFor="search-bar" className="dropdown">
                Company:
            </label>
            <Select
                id="search-bar"
                onChange={handleSearchChange}
                value={searchTerm ? { value: searchTerm, label: searchTerm } : null}
                placeholder="Search ticker, company ..."
                isSearchable={true}
                options={options}
                classNamePrefix="react-select"
                className="search-bar-select"
            />
        </div>
    );
};

export default SearchBar;