// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.treemap-hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: var(--article-text-color);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
    max-width: 1000px;
    margin: 0 auto;
    overflow: auto;
    transform-origin: center;
}

.treemap-hover:hover {
    transform: scale(1.05);
    background-color: #f5f5f5;
}

.treemap-hover h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: var(--article-heading-color);
}

.treemap-hover ul {
    list-style-type: none;
    padding-left: 0;
}

.treemap-hover li {
    margin-bottom: 10px;
    font-size: 1em;
    color: var(--article-text-color);
}

.treemap-hover li strong {
    color: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/components/TreeMap/TreeMapHover.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,gCAAgC;IAChC,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,yEAAyE;IACzE,iBAAiB;IACjB,cAAc;IACd,cAAc;IACd,wBAAwB;AAC5B;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,mCAAmC;AACvC;;AAEA;IACI,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,gCAAgC;AACpC;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".treemap-hover {\n    background-color: rgba(255, 255, 255, 0.5);\n    color: var(--article-text-color);\n    padding: 15px;\n    border-radius: 8px;\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);\n    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;\n    max-width: 1000px;\n    margin: 0 auto;\n    overflow: auto;\n    transform-origin: center;\n}\n\n.treemap-hover:hover {\n    transform: scale(1.05);\n    background-color: #f5f5f5;\n}\n\n.treemap-hover h3 {\n    font-size: 1.5em;\n    margin-bottom: 10px;\n    color: var(--article-heading-color);\n}\n\n.treemap-hover ul {\n    list-style-type: none;\n    padding-left: 0;\n}\n\n.treemap-hover li {\n    margin-bottom: 10px;\n    font-size: 1em;\n    color: var(--article-text-color);\n}\n\n.treemap-hover li strong {\n    color: var(--primary-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
