// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fundamental-charts-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-direction: row;
}

.fundamental-chart {
}

.fundamental-chart h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
}

.loading, .error {
    text-align: center;
    font-size: 1.2em;
    color: #666;
}`, "",{"version":3,"sources":["webpack://./src/components/Fundamentals/FundamentalCharts.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,mBAAmB;AACvB;;AAEA;AACA;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;AACf","sourcesContent":[".fundamental-charts-container {\n    display: flex;\n    justify-content: space-between;\n    gap: 20px;\n    flex-direction: row;\n}\n\n.fundamental-chart {\n}\n\n.fundamental-chart h2 {\n    text-align: center;\n    margin-bottom: 20px;\n    font-size: 1.5em;\n    color: #333;\n}\n\n.loading, .error {\n    text-align: center;\n    font-size: 1.2em;\n    color: #666;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
