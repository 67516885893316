import React, { useState, useEffect, use } from 'react';
import FundamentalCharts from './FundamentalCharts';
import FundamentalsFooter from './FundamentalsFooter';
import './Fundamentals.css';
import { axiosInstance, axiosInstanceWithPolling } from '../../axios';
import Loading from '../Loading/Loading';

const Fundamentals = ({ ticker, companyName, onLoadingChange }) => {
    const [interval, setInterval] = useState('annual');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        onLoadingChange(loading);
    } , [loading]);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const response = await axiosInstanceWithPolling(`/fundamentals/${ticker}/`, {}, 1000);
                setData(response.data)
            } catch (error) {
                console.error('Error fetching fundamentals data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [ticker]);

    if (loading) return <Loading />;

    return (
        <div className="fundamentals"> 
            {data?.data?.length !== 0 && (
                <>
                    <FundamentalCharts data={data} interval={interval} />
                    <FundamentalsFooter selectedInterval={interval} onSelectInterval={setInterval} />
                </>
            )}
        </div>
    );
};

export default Fundamentals;