// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  max-height: 100vh;
}

html{
    height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* or flex-start if you don't want stretching */
  max-height: 100vh;
  flex: 1 1;
}

h1 {
  color: #333;
}

a {
  color: inherit;
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,8BAA8B;EAC9B,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB,EAAE,+CAA+C;EACrE,iBAAiB;EACjB,SAAO;AACT;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: Arial, sans-serif;\n  background-color: #f5f5f5;\n  max-height: 100vh;\n}\n\nhtml{\n    height: 100%;\n}\n\n#root {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch; /* or flex-start if you don't want stretching */\n  max-height: 100vh;\n  flex: 1;\n}\n\nh1 {\n  color: #333;\n}\n\na {\n  color: inherit;\n  text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
