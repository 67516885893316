import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../../axios'; // Adjust the import path as necessary
import './Recaptcha.css'; // Import the CSS file

const Recaptcha = ({ onVerify }) => {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        const loadRecaptcha = () => {
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?render=6LcHfKcqAAAAANfCucq96tA4jlZ8leMKgHZBlEru`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        };

        loadRecaptcha();
    }, []);

    const handleClick = (e) => {
        e.preventDefault();
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LcHfKcqAAAAANfCucq96tA4jlZ8leMKgHZBlEru', { action: 'submit' }).then((token) => {
                // Send the token to your backend for verification
                axiosInstance.post('/recaptcha/', { recaptchaToken: token })
                    .then((response) => {
                        if (response.data.error) {
                            alert('Verification failed');
                        } else {
                            setIsChecked(true);
                            onVerify(true);
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        onVerify(false);
                    });
            });
        });
    };

    return (
        <div className="recaptcha-checkbox">
            <input type="checkbox" id="recaptcha-checkbox" checked={isChecked} onClick={handleClick} readOnly />
            <label htmlFor="recaptcha-checkbox">I am not a robot!</label>
        </div>
    );
};

export default Recaptcha;