import React from 'react';
import './TreeMapFooter.css';

const TreeMapFooter = ({filters}) => {
  return (
    <div className="treemap-footer">
      <div className="treemap-footer-text">
        <p>{filters.Country} market capitalization overview.</p>
        <p>Data is updated periodically and may have a delay.</p>
        <p>Use for general market understanding only.</p>
      </div>

      {/* Color scale */}
      <div className="color-scale">
        <div className="scale-item negative-3">-3%</div>
        <div className="scale-item negative-2">-2%</div>
        <div className="scale-item negative-1">-1%</div>
        <div className="scale-item zero">0%</div>
        <div className="scale-item positive-1">+1%</div>
        <div className="scale-item positive-2">+2%</div>
        <div className="scale-item positive-3">+3%</div>
      </div>
    </div>
  );
};

export default TreeMapFooter;