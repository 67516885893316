import React from 'react';
import './ChartHeader.css';

const ChartHeader = ({ ticker, companyName }) => {
    return (
        <div className="chart-header">
            <h1>{companyName} ({ticker})</h1>
        </div>
    );
};

export default ChartHeader;