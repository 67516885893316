import React from 'react';
import './TreeMapButtons.css';
import { IoPlayCircleOutline, IoStop } from "react-icons/io5";

const TreeMapButtons = ({ selectedPeriod, onSelectPeriod, onPlay, onStop, isPlayBusy }) => {
    const periods = ['Daily', '5 Days', '1 Month', '6 Months', 'YTD', '1 Year', '5 Years'];

    return (
        <div className="treemap-buttons">
            <button
                className={`play-button ${isPlayBusy ? 'loading' : ''}`}
                onClick={!isPlayBusy ? onPlay : onStop}
            >
                {isPlayBusy ? <IoStop size={13} color={'var(--dark-blue-color)'}/> : <IoPlayCircleOutline size={25} color={'var(--dark-blue-color)'}/>}
            </button>
            {periods.map(period => (
                <button
                    key={period}
                    className={selectedPeriod === period ? 'active' : ''}
                    onClick={() => onSelectPeriod(period)}
                    disabled={isPlayBusy}
                >
                    {period}
                </button>
            ))}
        </div>
    );
};

export default TreeMapButtons;
