// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* UnderConstruction.css */
.under-construction-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    background-color: #f5f5f5;
    text-align: center;
    color: #333;
}

.under-construction-text {
    font-size: 24px;
    margin-bottom: 20px;
}

.additional-text {
    font-size: 18px;
    color: #007bff;
}`, "",{"version":3,"sources":["webpack://./src/components/Construction/UnderConstruction.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB","sourcesContent":["/* UnderConstruction.css */\n.under-construction-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 70vh;\n    background-color: #f5f5f5;\n    text-align: center;\n    color: #333;\n}\n\n.under-construction-text {\n    font-size: 24px;\n    margin-bottom: 20px;\n}\n\n.additional-text {\n    font-size: 18px;\n    color: #007bff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
