import React from 'react';
import Chart from 'react-apexcharts';
import './PriceCandleStick.css'; // Import the CSS file
import dayjs from 'dayjs';

const getxLabels = (data, period) => {
    let lastLabel = '';
    const labelMap = new Map();
    const durationInYears = dayjs(data[data.length - 1].timestamp).diff(dayjs(data[0].timestamp), 'year');
    const durationInMonths = dayjs(data[data.length - 1].timestamp).diff(dayjs(data[0].timestamp), 'month');
    data.forEach(price => {
        const date = dayjs(price.timestamp);
        let label = '';

        switch (period) {
            case 'Daily':
                label = date.format('HH:mm');
                if (date.minute() % 30 !== 0) {
                    label = '';
                }
                break;
            case '5 Days':
                const formattedDay = date.format('ddd');
                if (formattedDay !== lastLabel) {
                    lastLabel = formattedDay;
                    label = formattedDay;
                }
                break;
            case '1 Month':
                const formattedDate = date.format('MMM D');
                if (formattedDate !== lastLabel) {
                    lastLabel = formattedDate;
                    label = formattedDate;
                }
                break;
            case '6 Months':
                if (date.date() === 1) {
                    label = date.format('MMM DD');
                }
                break;
            case 'YTD':
                if (durationInMonths < 3) {
                    const formattedDate = date.format('MMM D');
                    if (formattedDate !== lastLabel) {
                        lastLabel = formattedDate;
                        label = formattedDate;
                    }
                } else {
                    if (date.date() === 1) {
                        label = date.format('MMM DD');
                    }
                }
                break;
            case '1 Year':
                if (date.month() % 3 === 0 && date.date() === 1) {
                    label = date.format('MMM YYYY');
                }
                break;
            case '5 Years':
                const formattedYear = date.format('YYYY');
                if (formattedYear !== lastLabel) {
                    lastLabel = formattedYear;
                    label = formattedYear;
                }
                break;
            case 'Max':
                if (durationInYears < 3) {
                    label = date.format('MMM YYYY');
                } else {
                    const year = date.year();
                    if (year % 3 === 0 && year !== lastLabel) {
                        lastLabel = year;
                        label = year.toString();
                    }
                }
                break;
            default:
                label = price.timestamp;
                break;
        }

        labelMap.set(price.timestamp, label);
    });

    return labelMap;
};

const PriceCandleStick = ({ data, period }) => {
    if (!Array.isArray(data) || data.length === 0) {
        return null;
    }
    
    const formattedData = data.map(price => {
        return {
            x: price.timestamp,
            y: [price.open, price.high, price.low, price.close],
            open: price.open,
            high: price.high,
            low: price.low,
            close: price.close,
            timestamp: price.timestamp // Add the timestamp field
        };
    });

    const minY = Math.min(...formattedData.map(price => price.low));
    const maxY = Math.max(...formattedData.map(price => price.high));
    const xLabels = getxLabels(data, period);

    const options = {
        chart: {
            type: 'candlestick',
            height: '100%',
            width: '100%',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false // Disable zoom
            }
        },
        title: {
            text: `${period} Price`,
            align: 'center'
        },
        xaxis: {
            type: 'category',
            labels: {
                style: {
                    colors: '#333',
                    fontSize: '12px'
                },
                formatter: function(value) {
                    return xLabels.get(value);
                },
                rotateAlways: true,
                offsetX: -10,
                offsetY: 10
            },
            tooltip: {
                enabled: true,
                formatter: (value, { dataPointIndex, w }) => {
                    const data = w.globals.initialSeries[0].data[dataPointIndex];
                    return dayjs(data.timestamp).format('YYYY-MM-DD HH:mm');
                }
            }
        },
        tooltip: {
            enabled: true,
            x: {
                formatter: (value, { dataPointIndex, w }) => {
                    const data = w.globals.initialSeries[0].data[dataPointIndex];
                    return dayjs(data.timestamp).format('YYYY-MM-DD HH:mm');
                }
            },
            y: {
                formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                    const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                    return `
                        Open: ${data.open}<br/>
                        High: ${data.high}<br/>
                        Low: ${data.low}<br/>
                        Close: ${data.close}
                    `;
                }
            }
        },
        yaxis: {
            min: minY,
            max: maxY,
            tooltip: {
                enabled: true
            },
            labels: {
                align: 'left' // Align the y-axis labels to the left
            }
        }
    };

    const series = [{
        data: formattedData
    }];

    return (
            <Chart 
                options={options} 
                series={series} 
                type="candlestick"
                height={400}
                width={1000}
                className="apexcharts-canvas"
            />
    );
};

export default PriceCandleStick;