import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import "./Filters.css";
import SearchBar from "./SearchBar";
import { NewsContext } from "../../context/NewsContext";

const Filters = ({ chartType, onFilterChange }) => {
  const [filters, setFilters] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const news = useContext(NewsContext);
  const newsSources = [...new Set(news?.map(item => item.source))].map(source => ({ value: source, label: source }));
  const newsDates = [...new Set(news?.map(item => new Date(item.published_at).toLocaleDateString()))].map(data => ({ value: data, label: data }));

  useEffect(() => {
    let newFilters = [];
    switch (chartType) {
      case "Tree Map":
        newFilters = [
          { name: "Country", value: "United States" },
          { name: "Sector", value: "All Sectors" }
        ];
        break;
      case "Stock":
        newFilters = [
          { name: "Company", value: "AAPL" }
        ];
        break;
      case "News":
        newFilters = [
          { name: "Company", value: "" },
          { name: "Source", value: "" },
          { name: "Date", value: "" },
        ];
        break;
      default:
        newFilters = [];
    }
    setFilters(newFilters);
    const initialFilterValues = newFilters.reduce((acc, filter) => {
      acc[filter.name] = filter.value;
      return acc;
    }, {});
    setFilterValues(initialFilterValues);
    onFilterChange(initialFilterValues);
  }, [chartType]);

  const handleFilterChange = (filterName, selectedOption) => {
    setFilterValues((prevState) => {
      const updatedFilters = { ...prevState };
      updatedFilters[filterName] = selectedOption ? selectedOption.value : "";
      onFilterChange(updatedFilters);
      return updatedFilters;
    });
  };

  const generateFilterOptions = (filterName) => {
    let options = [];
    switch (filterName) {
      case "Country":
        options = [
          { value: "United States", label: "United States" },
          { value: "Canada", label: "Canada" },
          { value: "United Kingdom", label: "United Kingdom" }
        ];
        break;
      case "Sector":
        options = [
          { value: "All Sectors", label: "All Sectors" },
          { value: "Information Technology", label: "Information Technology" },
          { value: "Communication Services", label: "Communication Services" },
          { value: "Consumer Discretionary", label: "Consumer Discretionary" },
          { value: "Industrials", label: "Industrials" },
          { value: "Financials", label: "Financials" },
          { value: "Health Care", label: "Health Care" },
          { value: "Consumer Staples", label: "Consumer Staples" },
          { value: "Energy", label: "Energy" },
          { value: "Utilities", label: "Utilities" },
          { value: "Real Estate", label: "Real Estate" },
          { value: "Materials", label: "Materials" },
        ];
        break;
      case "Stock Charts":
        options = [
          { value: "Price", label: "Price" },
          { value: "Fundamentals", label: "Fundamentals" },
          { value: "Analysts Ratings", label: "Analysts Ratings" },
          { value: "News Sentiment", label: "News Sentiments" }
        ];
        break;
      case "Source":
        options = [{ value: "", label: "All Sources" }, ...newsSources];
        break;
      case "Date":
        options = [{ value: "", label: "All Dates" }, ...newsDates];
        break;
      default:
        options = [];
    }
    return options;
  };

  return (
    <div className="filters">
      {chartType !== "Tree Map" && chartType !== "News" && (
        <SearchBar onTickerSelect={(company) => {
          handleFilterChange("Company", { value: company.ticker })
          handleFilterChange("CompanyName", { value: company.name })
        }} />
      )}

      {filters
        .filter((filter) => filter.name !== "Company")
        .map((filter) => (
          <div key={filter.name} className="dropdown">
            <label className="dropdown-label">
              {filter.name}:
            </label>
            <Select
              classNamePrefix="react-select"
              options={generateFilterOptions(filter.name)}
              onChange={(selectedOption) => handleFilterChange(filter.name, selectedOption)}
              placeholder={`Select ${filter.name}`}
              value={filterValues[filter.name] ? { value: filterValues[filter.name], label: filterValues[filter.name] } : null}
              isSearchable={false} // Disable search functionality
            />
          </div>
        ))}
    </div>
  );
};

export default Filters;