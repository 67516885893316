import React from 'react';
import Chart from 'react-apexcharts';
import './FundamentalCharts.css';

const FundamentalCharts = ({ data, interval }) => {
    let scaleFactor;
    let scaleLabel;
    let revenueData, netIncomeData, grossProfitData, operatingIncomeData, freeCashFlowData;

    if (interval === 'quarterly') {
        revenueData = data.quarterly_revenue || [];
        netIncomeData = data.quarterly_net_income || [];
        grossProfitData = data.quarterly_gross_profit || [];
        operatingIncomeData = data.quarterly_operating_income || [];
        freeCashFlowData = data.quarterly_free_cash_flow || [];
    } else {
        revenueData = data.annual_revenue || [];
        netIncomeData = data.annual_net_income || [];
        grossProfitData = data.annual_gross_profit || [];
        operatingIncomeData = data.annual_operating_income || [];
        freeCashFlowData = data.annual_free_cash_flow || [];
    }

    const maxRevenue = Math.max(...revenueData.map(item => item.revenue));
    const maxNetIncome = Math.max(...netIncomeData.map(item => item.net_income));
    const maxGrossProfit = Math.max(...grossProfitData.map(item => item.gross_profit));
    const maxOperatingIncome = Math.max(...operatingIncomeData.map(item => item.operating_income));
    const maxFreeCashFlow = Math.max(...freeCashFlowData.map(item => item.free_cash_flow));
    const maxDataValue = Math.max(maxRevenue, maxNetIncome, maxGrossProfit, maxOperatingIncome, maxFreeCashFlow);

    if (maxDataValue >= 10**10) {
        scaleFactor = 10**9;
        scaleLabel = 'Billion';
    } else if (maxDataValue >= 10**6) {
        scaleFactor = 10**6;
        scaleLabel = 'Million';
    } else {
        scaleFactor = 1;
        scaleLabel = '';
    }

    const series = [
        {
            name: 'Revenue',
            data: revenueData.map(item => (item.revenue / scaleFactor).toFixed(1))
        },
        {
            name: 'Net Income',
            data: netIncomeData.map(item => (item.net_income / scaleFactor).toFixed(1))
        },
        {
            name: 'Gross Profit',
            data: grossProfitData.map(item => (item.gross_profit / scaleFactor).toFixed(1))
        },
        {
            name: 'Operating Income',
            data: operatingIncomeData.map(item => (item.operating_income / scaleFactor).toFixed(1))
        },
        {
            name: 'Free Cash Flow',
            data: freeCashFlowData.map(item => (item.free_cash_flow / scaleFactor).toFixed(1))
        }
    ];

    const categories = revenueData.map(item => interval === 'quarterly' ? item.quarter : item.year);

    const chartOptions = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '25%',
                borderRadius: 5,
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: categories,
            labels: {
                offsetX: -10
            }
        },
        yaxis: {
            title: {
                text: `${scaleLabel}`,
                align: 'low',
                offsetX: -35
            },
            labels: {
                align: 'left',
                formatter: function (val) {
                    return val.toFixed(0);
                }
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return `$ ${val} ${scaleLabel}`;
                }
            }
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'center',
            onItemClick: {
                toggleDataSeries: true
            }
        }
    };

    return (
        <div className="fundamental-charts-container">
            <div className="fundamental-chart root-box">
                <Chart options={chartOptions} series={series} type="bar" width={1000} height={350} />
            </div>
        </div>
    );
};

export default FundamentalCharts;