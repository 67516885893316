// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.treemap-container {
  display: grid;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden; /* Prevents overflow */
}`, "",{"version":3,"sources":["webpack://./src/components/TreeMap/TreeMap.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gBAAgB,EAAE,sBAAsB;AAC1C","sourcesContent":[".treemap-container {\n  display: grid;\n  flex-direction: column;\n  flex-grow: 1;\n  overflow: hidden; /* Prevents overflow */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
