import React from 'react';
import './PriceFooter.css';

const PriceFooter = ({ selectedPeriod, onSelectPeriod }) => {
    const periods = ['Daily', '5 Days', '1 Month', '6 Months', 'YTD', '1 Year', '5 Years', 'Max'];

    return (
        <div className="price-footer">
            {periods.map(period => (
                <button
                    key={period}
                    className={selectedPeriod === period ? 'active' : ''}
                    onClick={() => onSelectPeriod(period)}
                >
                    {period}
                </button>
            ))}
        </div>
    );
};

export default PriceFooter;