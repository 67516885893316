// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Loading.css */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f5f5f5;
}

.spinner {
    border: 4px solid rgba(0, 123, 255, 0.2); /* Light blue border */
    border-top: 4px solid #007bff; /* Primary blue color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,wCAAwC,EAAE,sBAAsB;IAChE,6BAA6B,EAAE,uBAAuB;IACtD,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;AACtC;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":["/* Loading.css */\n.loading-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    background-color: #f5f5f5;\n}\n\n.spinner {\n    border: 4px solid rgba(0, 123, 255, 0.2); /* Light blue border */\n    border-top: 4px solid #007bff; /* Primary blue color */\n    border-radius: 50%;\n    width: 40px;\n    height: 40px;\n    animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
