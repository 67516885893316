// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Market.css */
.market-container {
    display: flex;
    flex: 1 1;
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    height: 100%;
}

.filter-container {
    width: 300px;
    box-sizing: border-box;
    border-right: 2px solid var(--shadow-color);
    padding-right: 1rem;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Market/Market.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,aAAa;IACb,SAAO;IACP,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,2CAA2C;IAC3C,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":["/* Market.css */\n.market-container {\n    display: flex;\n    flex: 1;\n    width: 100%;\n    box-sizing: border-box;\n    padding: 1rem;\n    height: 100%;\n}\n\n.filter-container {\n    width: 300px;\n    box-sizing: border-box;\n    border-right: 2px solid var(--shadow-color);\n    padding-right: 1rem;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
