import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./TreeMapDetail.css";
import TreeMapHover from "./TreeMapHover"; // Assuming you have a TreeMapHover component
import { useNavigate } from 'react-router-dom';

const getTickerSize = (d) => {
  const width = d.x1 - d.x0
  const height = d.y1 - d.y0
  const areaFactor = Math.sqrt(width * height)
  const sizeByArea = areaFactor / 5
  const minSize = 7
  const maxSize = 30

  if (width < 20 || height < 15 ) {
    return 0
  }
  else {
    switch (true) {
      case (sizeByArea < minSize):
        return minSize;
      case (sizeByArea > maxSize):
        return maxSize;
      default:
        return sizeByArea;
    }
  }
}

const getSectorText = (width, text, fontSize) => {
  const shortTextMap = {
    "Technology": "Tech",
    "Healthcare": "Health",
    "Financials": "Fin",
    "Consumer Discretionary": "Cons Disc",
    "Consumer Staples": "Cons Stap",
    "Energy": "Energy",
    "Industrials": "Indust",
    "Materials": "Mat",
    "Utilities": "Util",
    "Real Estate": "Real Est",
    "Communication Services": "Comm Serv"
  };

  const charWidth = fontSize * 0.6; // Approximate width of each character
  const threshold = charWidth * text.length; // Threshold based on font size and text length

  if (width < threshold) {
    return shortTextMap[text] || text.slice(0, 5) + '...';
  } else {
    return text;
  }
};

const getTickerTemplate = (d) => {
  const priceChange = d.data.intradayPriceChange;
  const formattedChange = `${priceChange.toFixed(2)}%`; // Format change with 2 decimal places and % symbol
  const width = d.x1 - d.x0;
  const height = d.y1 - d.y0;
  const xoffset = (width < 40 || height < 25) ? ((d.x1 - d.x0) / 15) : ((d.x1 - d.x0) / 5);

  if (width < 30 || height < 25) {
    return `
    <tspan x="${xoffset}" dx="0em" dy="0em">${d.data.ticker}</tspan>
    `;
  } else {
    return `
    <tspan x="${xoffset}" dx="0em" dy="-0.4em">${d.data.ticker}</tspan>
    <tspan x="${xoffset}" dx="0em" dy="1.2em" style="font-size: 0.7em">${formattedChange}</tspan>
    `;
  }
}

const getBoxColor = (d) => {
  const priceChange = d.data.intradayPriceChange;
  switch (true) {
    case (priceChange <= -2):
      return "negative-3";
    case (priceChange <= -1):
      return "negative-2";
    case (priceChange < 0):
      return "negative-1";
    case (priceChange === 0):
      return "zero";
    case (priceChange >= 2):
      return "positive-3";
    case (priceChange >= 1):
      return "positive-2";
    case (priceChange > 0):
      return "positive-1";
    default:
      return "zero";
  }
};
    
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};


const handleRectClick = (d, navigate) => {
  const ticker = d.data.ticker;
  const filters = {
    'Stock Charts': 'Price',
    'Company': ticker,
    'CompanyName': d.data.name
  };
  const params = new URLSearchParams({
    chartType: 'Stock',
    filters: JSON.stringify(filters)
  });
  navigate(`/price/${ticker}?${params.toString()}`);
  window.location.reload();
};

const TreeMapDetail = ({ data }) => {
  const d3Container = useRef(null);
  const [hoveredSector, setHoveredSector] = useState(null);
  const [dimensions, setDimensions] = useState({
    width: Math.max(window.innerWidth * 0.77, 1000),
    height: Math.max(window.innerHeight * 0.6, 500), // 80% of window height
  });
  const navigate = useNavigate();

  // Update dimensions on window resize
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (data && d3Container.current) {
      // Set dimensions
      const { width, height } = dimensions;
      const verticalGap = 17
      const horizontalGap = 10
      const sectorOffset = 11

      // Clear previous chart
      d3.select(d3Container.current).select("svg").remove();

      // Group data by Sector
      const nestedData = d3.group(data, d => d.sector);
      const formattedData = Array.from(nestedData, ([sector, children]) => ({
        sector: sector,
        children
      }));

      // Create root hierarchy
      const root = d3
        .hierarchy({ children: formattedData })
        .sum(d => d.marketCap) // Rectangle size proportional to market cap
        .sort((a, b) => b.value - a.value);

      // Generate tree map
      const treemap = d3.treemap()
        .size([width, height])
        .paddingTop(verticalGap)
        .paddingRight(horizontalGap)
        .paddingInner(1)
        .round(true);

      treemap(root);

      // Append SVG
      const svg = d3.select(d3Container.current)
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      // Draw each sector as a group
      // Group small sectors into "Other"
      const smallSectors = root.children.filter(d => (d.x1 - d.x0) * (d.y1 - d.y0) <= 2000);
      const largeSectors = root.children.filter(d => (d.x1 - d.x0) * (d.y1 - d.y0) > 2000);

      if (smallSectors.length > 0) {
        const otherSector = {
          data: { sector: "Other" },
          x0: d3.min(smallSectors, d => d.x0),
          x1: d3.max(smallSectors, d => d.x1),
          y0: d3.min(smallSectors, d => d.y0),
          y1: d3.max(smallSectors, d => d.y1),
          children: smallSectors.flatMap(d => d.children)
        };
        largeSectors.push(otherSector);
      }

      const sectorGroups = svg.selectAll("g.sector")
        .data(largeSectors)
        .enter()
        .append("g")
        .attr("class", "sector")
        //.on("mouseover", debounce((_event, d) => setHoveredSector(d), 100)) // Enable hover effect
        //.on("mouseout", debounce(() => setHoveredSector(null), 1000));      // Enable hover effect

      // Add sector names in the top-left corner
      const fontSize = 12; // Define fontSize variable
      sectorGroups.append("text")
        .attr("x", d => d.x0) // Add slight padding for readability
        .attr("y", d => d.y0 + sectorOffset) // Position slightly below the top edge
        .attr("fill", "black")
        .attr("font-size", `${fontSize}px`) // Ensure it's readable
        .attr("font-weight", "normal")
        .text(d => getSectorText(d.x1 - d.x0, d.data.sector, fontSize)); // Access sector name correctly

      // Add ticker rectangles inside each sector
      sectorGroups.each(function(sector) {
        const cell = d3.select(this)
          .selectAll("g.cell")
          .data(sector.children)
          .enter()
          .append("g")
          .attr("transform", d => `translate(${d.x0},${d.y0})`);

          cell.append("rect")
          .attr("width", d => d.x1 - d.x0)
          .attr("height", d => d.y1 - d.y0)
          .attr("style", "stroke: #fff")
          .attr("class", d => getBoxColor(d))
          .on("click", (event, d) => handleRectClick(d, navigate))
          .on("mouseover", function() {
            d3.select(this).attr("style", "opacity: 90%; stroke: gold;");
          })
          .on("mouseout", function() {
            d3.select(this).attr("style", "stroke: #fff;");
          });

        // Add ticker symbols
        cell.append("text")
          .attr("x", d => (d.x1 - d.x0) / 2)
          .attr("y", d => (d.y1 - d.y0) / 2)
          .attr("text-anchor", "middle")
          .attr("dominant-baseline", "middle")
          .attr("fill", "black")
          .attr("font-family", "Roboto")
          .style("font-size", d => {
            const sizeOfRect =  getTickerSize(d)
            return `${sizeOfRect}px`;
          })
          .html(d => getTickerTemplate(d));
      });
    }
  }, [data]);

  return (
    <div className="treemap-detail-container" ref={d3Container}>
      {hoveredSector && (
        <div
          className="modal"
          style={{
            position: "absolute",
            top: `${hoveredSector.y0 + d3Container.current.getBoundingClientRect().top + window.scrollY + 100}px`,
            left: `${hoveredSector.x0 + d3Container.current.getBoundingClientRect().left + window.scrollX + 100}px`,
          }}
        >
          <TreeMapHover sector={hoveredSector} />
        </div>
      )}
    </div>
  );
};

export default TreeMapDetail;