// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TreeMap.css */
.treemap-detail-container {
  flex-direction: column;
  align-items: left; /* Or desired alignment */
  padding: 2%; /* Or adjust individual padding values */
  font-family: sans-serif;
}

rect {
  border: 1px solid #ddd;
  padding-bottom: 10px;
  stroke: #fff;
  stroke-width: 1px;
  cursor: pointer;
}

text {
  pointer-events: none;
  font-weight: bold;
  text-anchor: start;
}`, "",{"version":3,"sources":["webpack://./src/components/TreeMap/TreeMapDetail.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,sBAAsB;EACtB,iBAAiB,EAAE,yBAAyB;EAC5C,WAAW,EAAE,wCAAwC;EACrD,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,YAAY;EACZ,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":["/* TreeMap.css */\n.treemap-detail-container {\n  flex-direction: column;\n  align-items: left; /* Or desired alignment */\n  padding: 2%; /* Or adjust individual padding values */\n  font-family: sans-serif;\n}\n\nrect {\n  border: 1px solid #ddd;\n  padding-bottom: 10px;\n  stroke: #fff;\n  stroke-width: 1px;\n  cursor: pointer;\n}\n\ntext {\n  pointer-events: none;\n  font-weight: bold;\n  text-anchor: start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
