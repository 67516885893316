import React from "react";
import PropTypes from "prop-types";
import "./TreeMapHover.css";

const TreeMapHover = ({ sector }) => {
    return (
        <div className="treemap-hover">
            <h3>{sector.data.sector}</h3>
            <ul>
                {sector.children.slice(0, 10).map((child, index) => (
                    <li key={index}>
                        <strong>{child.data.ticker}</strong>: {child.data.intradayPriceChange.toFixed(2)}%
                    </li>
                ))}
            </ul>
        </div>
    );
};

TreeMapHover.propTypes = {
    sector: PropTypes.object.isRequired,
};

export default TreeMapHover;