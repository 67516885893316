import React from 'react';
import './About.css';

const About = () => {
    return (
        <div className="about-container">
            <h1>About Market Fractals</h1>
            <p>
                Welcome to Market Fractals! Our website provides an AI assistant to help you invest in the stock market. 
                Our advanced algorithms analyze market trends and provide insights to help you make informed investment decisions.
            </p>
            <p>
                At Market Fractals, we believe that everyone should have access to the tools and knowledge needed to succeed in the stock market. 
                Our AI assistant is designed to be user-friendly and accessible to investors of all experience levels. Whether you are a seasoned trader 
                or just starting out, our platform offers valuable resources to help you achieve your financial goals.
            </p>
            <p>
                Our team of experts is dedicated to continuously improving our algorithms and expanding our features to provide you with the best possible 
                experience. We are committed to transparency and strive to provide accurate and up-to-date information to help you make the best investment 
                decisions.
            </p>
            <p>
                Thank you for choosing Market Fractals as your trusted partner in the stock market. We look forward to helping you navigate the complexities 
                of investing and achieve success.
            </p>
        </div>
    );
}

export default About;