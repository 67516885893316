// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.treemap-header {
    background-color: #f5f5f5;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    text-align: right;
}

.treemap-header p {
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/TreeMap/TreeMapHeader.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,6BAA6B;IAC7B,8BAA8B;IAC9B,eAAe;IACf,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,SAAS;AACb","sourcesContent":[".treemap-header {\n    background-color: #f5f5f5;\n    padding: 10px;\n    border-bottom: 1px solid #ddd;\n    font-family: Arial, sans-serif;\n    font-size: 14px;\n    color: #333;\n    text-align: right;\n}\n\n.treemap-header p {\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
