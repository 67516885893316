// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-header {
    display: flex;
    justify-content: center;
}

.chart-header h1 {
    font-size: 2rem;
    color: var(--article-title-color);
}`, "",{"version":3,"sources":["webpack://./src/components/Chart/ChartHeader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,iCAAiC;AACrC","sourcesContent":[".chart-header {\n    display: flex;\n    justify-content: center;\n}\n\n.chart-header h1 {\n    font-size: 2rem;\n    color: var(--article-title-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
