import React, { useEffect, useState } from 'react';
import { axiosInstance, axiosInstanceWithPolling } from '../../axios';
import PriceFooter from './PriceFooter';
import PriceCandleStick from './PriceCandleStick';
import Loading from '../Loading/Loading'; // Adjust the path as necessary
import './PriceChart.css';

const PriceChart = ({ ticker, onLoadingChange }) => {
    const [selectedPeriod, setSelectedPeriod] = useState('6 Months');
    const [priceData, setPriceData] = useState({});
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        onLoadingChange(loading);
    }, [loading]);

    const fetchPriceDataWithTaskStatus = async (ticker, period) => {
        try {
            const response = await axiosInstanceWithPolling(`/price/${ticker}/${period}`, {}, 1000);
            if (response.data && response.data.length > 0) {
                setPriceData(prevData => ({
                    ...prevData,
                    [`${ticker}-${period}`]: response.data
                }));
            }
        } catch (error) {
            console.error('Error fetching price data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const key = `${ticker}-${selectedPeriod}`;
        if (!priceData[key]) {
            setLoading(true);
            fetchPriceDataWithTaskStatus(ticker, selectedPeriod);
        } else {
            setLoading(false);
        }
    }, [selectedPeriod, ticker]);

    const handleSelectPeriod = (period) => {
        setSelectedPeriod(period);
    };

    if (loading) return <Loading />;

    const key = `${ticker}-${selectedPeriod}`;
    return (
        <div className="price-chart-container">
            <div className="price-chart root-box">
                <PriceCandleStick data={priceData[key]} period={selectedPeriod} />
            </div>
            <div className="price-footer">
                <PriceFooter selectedPeriod={selectedPeriod} onSelectPeriod={handleSelectPeriod} />
            </div>
        </div>
    );
};

export default PriceChart;
