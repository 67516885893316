// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    background-color: #f5f5f5;
    color: #333;
}

.not-found h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: var(--dark-blue-color);
}

.not-found p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
}

.not-found a {
    font-size: 1rem;
    color: #000;
    text-decoration: none;
    border: none;
    margin: 1px;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background-color: #e0e0e080; /* Added grey background */
    transition: background-color 0.3s ease, color 0.3s ease;
}

.not-found a:hover {
    background-color: var(--light-blue-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFound/NotFound.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,qBAAqB;IACrB,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,oBAAoB;IACpB,eAAe;IACf,2BAA2B,EAAE,0BAA0B;IACvD,uDAAuD;AAC3D;;AAEA;IACI,yCAAyC;AAC7C","sourcesContent":[".not-found {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    text-align: center;\n    background-color: #f5f5f5;\n    color: #333;\n}\n\n.not-found h1 {\n    font-size: 3rem;\n    margin-bottom: 1rem;\n    color: var(--dark-blue-color);\n}\n\n.not-found p {\n    font-size: 1.25rem;\n    margin-bottom: 2rem;\n}\n\n.not-found a {\n    font-size: 1rem;\n    color: #000;\n    text-decoration: none;\n    border: none;\n    margin: 1px;\n    border-radius: 10px;\n    padding: 0.5rem 1rem;\n    cursor: pointer;\n    background-color: #e0e0e080; /* Added grey background */\n    transition: background-color 0.3s ease, color 0.3s ease;\n}\n\n.not-found a:hover {\n    background-color: var(--light-blue-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
