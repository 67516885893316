import React, { useState, useEffect } from "react";
import Chart from "../../components/Chart/Chart";
import ChartOptions from "../../components/ChartOptions/ChartOptions";
import Filters from "../../components/Filters/Filters";
import './Market.css'
import { NewsProvider } from "../../context/NewsContext";

function Market({ initialChartType, initialFilters }) {
    const [selectedChart, setSelectedChart] = useState(initialChartType || "Tree Map");
    const [filters, setFilters] = useState(initialFilters || {});
    const [isClickThrough, setIsClickThrough] = useState(initialChartType || initialFilters ? true : false);

    useEffect(() => {
        setIsClickThrough(false);
    }, []);

    const handleChartOptionSelect = (option) => {
        if (!isClickThrough) {
            setSelectedChart(option);
        }
    };

    const handleFilterChange = (updatedFilters) => {
        if (!isClickThrough) {
            setFilters(updatedFilters);
        }
    };

    return (
        <NewsProvider>
            <div className="market-container">
                <div className="filter-container">
                    <ChartOptions 
                        selectedOption={{ value: selectedChart, label: selectedChart }} 
                        onChartOptionSelect={handleChartOptionSelect}
                    />
                    <Filters chartType={selectedChart} onFilterChange={handleFilterChange}/>
                </div>
                <Chart chartType={selectedChart} filters={filters} />
            </div>
        </NewsProvider>
    );
}

export default Market;