import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Wiki.css';
import Select from 'react-select';

const Wiki = () => {
    const defaultData = {
        todaysPick: {
            title: "AI in Unveiling Sentiments for Companies",
            summary: `Artificial Intelligence (AI) is transforming how companies understand and act on public sentiment, offering powerful tools to analyze vast amounts of unstructured data from sources like social media, reviews, news articles, and financial reports. Through advanced Natural Language Processing (NLP), AI can interpret the tone, context, and subtleties of text, allowing businesses to grasp the public perception of their brand and services.
                        One of the most impactful applications of AI in sentiment analysis is its ability to provide real-time insights. By monitoring social media and online platforms, companies can identify emerging trends and gauge customer reactions immediately. This capability is invaluable during product launches or public relations crises, where timely response can significantly influence outcomes. Early detection of negative sentiment, for instance, enables businesses to address issues proactively, mitigating potential reputational damage.
                        AI also provides competitive insights by benchmarking sentiment for a company against its competitors. This allows organizations to identify market gaps, understand their relative strengths, and make data-driven decisions to improve their standing. Moreover, sentiment analysis tools help businesses track customer feedback through surveys and reviews, offering actionable insights into common pain points and areas for improvement.
                        In the financial sector, AI-driven sentiment analysis has revolutionized how companies and investors assess market movements. By analyzing public sentiment related to financial news, stock performance, and investor discussions, AI helps businesses and traders make informed decisions about investments and risk management. Its predictive capabilities can even forecast sentiment trends, giving companies a strategic advantage by helping them anticipate public reactions and prepare accordingly.
                        Multilingual AI systems expand this capability globally, providing organizations with a comprehensive view of how their brand is perceived across diverse regions and languages. In customer interactions, sentiment-aware chatbots enhance engagement by tailoring responses based on the customer’s emotional tone, building loyalty and trust.
                        AI’s role in sentiment analysis extends beyond understanding the current mood; it shapes how companies approach customer relations, marketing strategies, and even crisis management. As AI continues to advance, its ability to decode sentiment will become even more precise, empowering businesses to act with greater insight and confidence in an increasingly dynamic and connected world.`
            },
        trending: [
            {
                title: "Top 10 Investment Strategies for 2024",
                summary: "Stay ahead in the financial game with these expert tips."
            },
            {
                title: "The Future of Renewable Energy",
                summary: "Explore how cutting-edge technologies are shaping a greener tomorrow."
            },
            {
                title: "Understanding the Rise of NFTs in Art",
                summary: "What makes NFTs valuable, and how are they changing the art world?"
            }
        ],
        didYouKnow: [
            "The first recorded stock market trade dates back to 1602 with the Dutch East India Company, setting the foundation for today’s global exchanges.",
            "A single Bitcoin transaction uses as much electricity as the average U.S. household does in a month—an important fact for understanding the environmental impact of cryptocurrency investments.",
            "Warren Buffett bought his first stock at the age of 11, proving it’s never too early to start learning about investing."
        ]
    };

    const [data, setData] = useState({
        todaysPick: {},
        trending: [],
        didYouKnow: [],
    });

    useEffect(() => {
        axios.get('/wiki')
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                setData(defaultData);
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <div className="wiki-container">
            <div className="top-bar">
                <div className="wiki-search-bar">
                    <Select
                        classNamePrefix="react-select"
                        options={[]} // Add options for search suggestions if needed
                        placeholder="Search..."
                        isClearable
                    />
                </div>
                <div className="language-selector">
                    <Select
                        classNamePrefix="react-select"
                        options={[
                            { value: 'en', label: 'English' },
                            { value: 'es', label: 'Spanish' },
                            { value: 'fr', label: 'French' }
                            // Add more languages as needed
                        ]}
                        defaultValue={{ value: 'en', label: 'English' }}
                        isSearchable={false}
                    />
                </div>
            </div>
            <div className="top-content">
                <div className="todays-pick">
                    <h2 className="section-title">Today's Pick</h2>
                    <h3>{data.todaysPick.title}</h3>
                    <p>{data.todaysPick.summary}</p>
                </div>
                <div className="trending-articles">
                    <h2 className="section-title">Trending</h2>
                    <ul>
                        {data.trending.map((article, index) => (
                            <li key={index}>
                                <h3>{article.title}</h3>
                                <p>{article.summary}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="did-you-know">
                <h2 className="section-title">Did You Know?</h2>
                <ul>
                    {data.didYouKnow.map((fact, index) => (
                        <li key={index}>{fact}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Wiki;