// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Chart.css */
.chart-container {
  flex-grow: 1; /* Allows the chart to take up available space */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.scrollable-container {
  flex-direction: column;
  max-height: 100%;
  box-sizing: border-box;
  width: 100%;
}

.hidden {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Chart/Chart.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,YAAY,EAAE,gDAAgD;EAC9D,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":["/* Chart.css */\n.chart-container {\n  flex-grow: 1; /* Allows the chart to take up available space */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  box-sizing: border-box;\n  overflow: hidden;\n}\n\n.scrollable-container {\n  flex-direction: column;\n  max-height: 100%;\n  box-sizing: border-box;\n  width: 100%;\n}\n\n.hidden {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
