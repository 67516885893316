// App.js
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Market from "./pages/Market/Market";
import About from "./pages/About/About";
import Advertise from "./pages/Advertise/Advertise";
import AI from "./pages/AI/AI";
import Wiki from "./pages/Wiki/Wiki";
import NotFound from "./pages/NotFound/NotFound";

function App() {
    return (
        <Router>
            <div className="app-container">
                <Header />
                <main className="main-content">
                    <Routes>
                        <Route path="*" element={<NotFound />} />
                        <Route path="/" exact element={<Market />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/advertise" element={<Advertise />} />
                        <Route path="/ai" element={<AI />} />
                        <Route path="/wiki" element={<Wiki />} />
                        <Route
                            path="/price/:ticker"
                            element={
                                <Market
                                    initialChartType={new URLSearchParams(window.location.search).get('chartType')}
                                    initialFilters={JSON.parse(new URLSearchParams(window.location.search).get('filters') || '{}')}
                                />
                            }
                        />
                        {/* Add other routes as needed */}
                    </Routes>
                </main>
                <Footer />
            </div>
        </Router>
    );
}

export default App;