import React from "react";
import PropTypes from "prop-types";
import "./TreeMapHeader.css";

function TreeMapHeader({ timestamp }) {
    const formattedTimestamp = new Date(timestamp).toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });

    return (
        <div className="treemap-header">
            <p>Last updated: {formattedTimestamp}</p>
        </div>
    );
}

TreeMapHeader.propTypes = {
    timestamp: PropTypes.string.isRequired,
};

export default TreeMapHeader;