// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company-news-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    max-width: 1200px; /* Set your desired maximum width here */
    height: 500px; /* Set your desired fixed height here */
    overflow-y: auto; /* Enable vertical scrolling */
    background-color: #fff;
    box-shadow: 0 2px 4px var(--shadow-color);
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.company-news-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.company-news-item:last-child {
    border-bottom: none;
}

.company-news-date,
.company-news-hour {
    font-size: 0.9rem;
    color: var(--secondary-color);
    margin-right: 10px;
}

.hidden-date {
    visibility: hidden;
}

.company-news-title {
    font-size: 0.9rem;
    color: var(--article-title-color);
    text-decoration: none;
    margin-right: 10px;
}

.company-news-title:hover {
    text-decoration: underline;
}

.company-news-source {
    font-size: 0.8rem;
    color: var(--wiki-text-color);
}`, "",{"version":3,"sources":["webpack://./src/components/News/CompanyNews.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,iBAAiB,EAAE,wCAAwC;IAC3D,aAAa,EAAE,uCAAuC;IACtD,gBAAgB,EAAE,8BAA8B;IAChD,sBAAsB;IACtB,yCAAyC;IACzC,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;;IAEI,iBAAiB;IACjB,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,iCAAiC;IACjC,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;IACjB,6BAA6B;AACjC","sourcesContent":[".company-news-container {\n    display: flex;\n    flex-direction: column;\n    padding: 20px;\n    width: 100%;\n    max-width: 1200px; /* Set your desired maximum width here */\n    height: 500px; /* Set your desired fixed height here */\n    overflow-y: auto; /* Enable vertical scrolling */\n    background-color: #fff;\n    box-shadow: 0 2px 4px var(--shadow-color);\n    border-radius: 8px;\n    margin-top: 20px;\n    margin-bottom: 20px;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.company-news-item {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n.company-news-item:last-child {\n    border-bottom: none;\n}\n\n.company-news-date,\n.company-news-hour {\n    font-size: 0.9rem;\n    color: var(--secondary-color);\n    margin-right: 10px;\n}\n\n.hidden-date {\n    visibility: hidden;\n}\n\n.company-news-title {\n    font-size: 0.9rem;\n    color: var(--article-title-color);\n    text-decoration: none;\n    margin-right: 10px;\n}\n\n.company-news-title:hover {\n    text-decoration: underline;\n}\n\n.company-news-source {\n    font-size: 0.8rem;\n    color: var(--wiki-text-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
