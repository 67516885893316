import React, { useState, useEffect } from 'react';
import './Header.css';
import { Search } from 'lucide-react';

const Header = () => {
    const [currentTime, setCurrentTime] = useState('');
    
    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
      
            const options = {
              weekday: 'short', // Short weekday name (e.g., Wed)
              month: 'short',   // Short month name (e.g., Dec)
              day: 'numeric',   // Day of the month (e.g., 11)
              year: 'numeric',
              hour: 'numeric',  // Hour (12-hour format)
              minute: '2-digit', // Minutes with leading zero
              hour12: true,     // Use 12-hour format
              timeZoneName: 'short'
            };
      
            const formattedTime = now.toLocaleString('en-US', options);
            setCurrentTime(formattedTime);
          };
      
          updateTime(); // Initial update
          const intervalId = setInterval(updateTime, 1000); // Update every second
      
          return () => clearInterval(intervalId); // Clean up interval on unmount
        }, []);

    return (
        <header className="header">
        <div className="header-content">
            <div className="logo-section">
            <a href="/">
                <img src="/word-logo.png" alt="" className="logo" />
            </a>
            </div>

            {/* Search section */}
            <div className="search-section">
            <div className="search-container">
                <Search className="search-icon" size={18} />
                <input 
                type="text" 
                placeholder="Ask AI" 
                className="search-input"
                />
            </div>
            </div>

            {/* Navigation section */}
            <nav className="main-nav">
            <ul className="nav-list">
                <li><a href="/" className="nav-link">Market</a></li>
                <li><a href="/ai" className="nav-link">AI Insights</a></li>
                <li><a href="/wiki" className="nav-link">Wiki</a></li>
            </ul>
            </nav>

            {/* User section */}
            <div className="user-section">
            <span className="datetime">{currentTime}</span>
            <button className="about-button" onClick={() => window.location.href = '/about'}>About</button>
            </div>
        </div>
        </header>
    );
};

export default Header;