import React, { useContext } from 'react';
import { NewsContext } from '../../context/NewsContext';
import './NewsBulletin.css';
import Loading from '../Loading/Loading';

const NewsBulletin = ({ companyName, source, date }) => {
    const news = useContext(NewsContext);

    if (news.length === 0) {
        return <Loading />;
    }

    const filteredNews = news.filter(item => {
        const matchesCompany = companyName ? item.content.includes(companyName) : true;
        const matchesSource = source ? item.source === source : true;
        const matchesDate = date ? new Date(item.published_at).toDateString() === new Date(date).toDateString() : true;
        return matchesCompany && matchesSource && matchesDate;
    });

    return (
        <div className="news-bulletin">
            <h1 className="news-bulletin-title">Today's Financial News</h1>
            {filteredNews.length > 0 ? (
                filteredNews.map((item, index) => (
                    <div key={index} className="news-bulletin-container">
                        <h2 className="news-bulletin-item-title">{item.title}</h2>
                        <div className="news-bulletin-item">
                            <div className="news-bulletin-item-image">
                                <img src={item.image_url || 'https://example.com/default-financial-image.jpg'} alt="News" />
                            </div>
                            <div className="news-bulletin-item-text">
                                <p className="news-bulletin-item-summary">{item.summary}</p>
                                <a href={item.url} className="news-bulletin-item-link" target="_blank" rel="noopener noreferrer">Read more</a>
                                <p className="news-bulletin-item-source">Source: {item.source}</p>
                                <p className="news-bulletin-item-date">Published at: {new Date(item.published_at).toLocaleString()}</p>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p>No news available for the selected company.</p>
            )}
        </div>
    );
};

export default NewsBulletin;