import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './ChartOptions.css';

const ChartOptions = ({ selectedOption: initialSelectedOption, onChartOptionSelect }) => {
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption || { value: 'Tree Map', label: 'Market Map' }); // Default selection

  useEffect(() => {
    setSelectedOption(initialSelectedOption);
  }, [initialSelectedOption]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (onChartOptionSelect) {
      onChartOptionSelect(option.value); // Call the callback function with the selected option value
    }
  };

  const options = [
    { value: 'Tree Map', label: 'Market Map' },
    { value: 'Stock', label: 'Stock' },
    { value: 'News', label: 'News' },
  ];

  return (
    <div className="chart-options">
      <p className="dropdown-label">Please select a chart type:</p>
      <Select
        classNamePrefix="react-select"
        value={selectedOption}
        onChange={handleOptionChange}
        options={options}
        placeholder="Select Chart Option"
        isSearchable={false} // Disable search functionality
      />
    </div>
  );
};

export default ChartOptions;