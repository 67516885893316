import React from 'react';
import './UnderConstruction.css';

const UnderConstruction = ({ additionalText }) => {
    return (
        <div className="under-construction-container">
            <div className="under-construction-text">
                Coming Soon!
            </div>
            {additionalText && <div className="additional-text">{additionalText}</div>}
        </div>
    );
};

export default UnderConstruction;