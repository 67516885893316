// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-container {
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 2rem auto;
}

.about-container h1 {
    font-size: 2.5rem;
    color: var(--dark-blue-color);
    margin-bottom: 1rem;
    text-align: center;
}

.about-container p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1rem;
    line-height: 1.6;
}

.about-container p:last-child {
    margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/About/About.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,6BAA6B;IAC7B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".about-container {\n    padding: 2rem;\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    max-width: 800px;\n    margin: 2rem auto;\n}\n\n.about-container h1 {\n    font-size: 2.5rem;\n    color: var(--dark-blue-color);\n    margin-bottom: 1rem;\n    text-align: center;\n}\n\n.about-container p {\n    font-size: 1rem;\n    color: #555;\n    margin-bottom: 1rem;\n    line-height: 1.6;\n}\n\n.about-container p:last-child {\n    margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
