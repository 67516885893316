// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apexcharts-canvas {
    background: #f4f4f4;
    border-radius: 8px;
}

.apexcharts-tooltip {
    background: rgba(255, 255, 255, 0.8); /* white background with 80% opacity */
    color: var(--dark-blue-color);
    border-radius: 4px;
    padding: 10px;
}

.apexcharts-xaxis-label,
.apexcharts-yaxis-label {
    font-size: 12px;
    font-weight: bold;
    color: #333;
}

.apexcharts-title-text {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Price/PriceCandleStick.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,oCAAoC,EAAE,sCAAsC;IAC5E,6BAA6B;IAC7B,kBAAkB;IAClB,aAAa;AACjB;;AAEA;;IAEI,eAAe;IACf,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".apexcharts-canvas {\n    background: #f4f4f4;\n    border-radius: 8px;\n}\n\n.apexcharts-tooltip {\n    background: rgba(255, 255, 255, 0.8); /* white background with 80% opacity */\n    color: var(--dark-blue-color);\n    border-radius: 4px;\n    padding: 10px;\n}\n\n.apexcharts-xaxis-label,\n.apexcharts-yaxis-label {\n    font-size: 12px;\n    font-weight: bold;\n    color: #333;\n}\n\n.apexcharts-title-text {\n    font-size: 16px;\n    font-weight: bold;\n    color: #333;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
