import axios from "axios";

// Create an axios instance with default configurations
const apiUrl = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: apiUrl, // The base URL for your API
  timeout: 100000, // Optional: Set a timeout in ms for requests
  headers: {
    "Content-Type": "application/json", // Default content type
  },
});

// Function to fetch the token from the backend
const fetchToken = async (url) => {
  try {
    const response = await axios.get(`${apiUrl}/generate-token/`, {
      params: { url }
    });
    return response.data.token;
  } catch (error) {
    console.error("Error fetching token:", error);
    throw error;
  }
};

// Function to get CSRF token from cookies or meta tag
const getCsrfToken = () => {
  const csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken='));
  return csrfToken ? csrfToken.split('=')[1] : null;
};

// Request interceptor to add the token to the headers
axiosInstance.interceptors.request.use(
  async (config) => {
    if (!config.skipToken) {
      try {
        const token = await fetchToken(config.url);
        config.headers['Authorization'] = `Bearer ${token}`;
        config.withCredentials = true;
        // Add CSRF token for POST requests
        if (config.method === 'post') {
          const csrfToken = getCsrfToken();
          if (csrfToken) {
            config.headers['X-CSRFToken'] = csrfToken;
          }
        }
      } catch (error) {
        console.error("Error adding token to request:", error);
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Global response or error interceptors
axiosInstance.interceptors.response.use(
  (response) => response, // Pass through responses
  (error) => {
    console.error("API request error:", error); // Log errors for debugging
    return Promise.reject(error); // Reject the promise to allow catch blocks to handle it
  }
);

// Function to poll the task status and fetch the data
const axiosInstanceWithPolling = async (url, config = {}, interval = 1000) => {
  const checkTaskStatus = async (taskId) => {
    try {
      const response = await axiosInstance.get(`${apiUrl}/task-status/${taskId}`, { ...config, skipToken: true });
      if (response.status === 200) {
        return true;
      } else {
        await new Promise(resolve => setTimeout(resolve, interval));
        return checkTaskStatus(taskId);
      }
    } catch (error) {
      throw error;
    }
  };

  const initialRequest = async () => {
    try {
      const response = await axiosInstance.get(url, config);
      if (response.status === 202) {
        const taskId = response.data.task_id;
        await checkTaskStatus(taskId);
        return axiosInstance.get(url, config);
      } else {
        return response;
      }
    } catch (error) {
      throw error;
    }
  };

  return initialRequest();
};

export { axiosInstance, axiosInstanceWithPolling };