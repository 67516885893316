import React from 'react';
import './FundamentalsFooter.css';

const FundamentalsFooter = ({ selectedInterval, onSelectInterval }) => {
    const intervals = ['Annual', 'Quarterly'];

    return (
        <div className="fundamentals-footer">
            {intervals.map(interval => (
                <button
                    key={interval}
                    className={selectedInterval === interval.toLowerCase() ? 'active' : ''}
                    onClick={() => onSelectInterval(interval.toLowerCase())}
                >
                    {interval}
                </button>
            ))}
        </div>
    );
};

export default FundamentalsFooter;