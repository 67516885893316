import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { axiosInstance } from "../../axios";
import TreeMapDetail from "./TreeMapDetail";
import TreeMapFooter from "./TreeMapFooter";
import "./TreeMap.css";
import Loading from "../Loading/Loading";
import TreeMapButtons from "./TreeMapButtons";
import TreeMapHeader from "./TreeMapHeader";

function TreeMap({ filters }) {
  const [treeMapData, setTreeMapData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState('Daily');
  const [treeMapAnimationData, setTreeMapAnimationData] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayLoading, setIsPlayLoading] = useState(false);
  const [displayedTimestamp, setDisplayedTimestamp] = useState('');
  const animationRef = useRef(null);
  const originalStateRef = useRef({});

  const filterData = (data, filters) => {
    if (!data || data.length === 0 || !filters) {
      return data;
    }

    Object.entries(filters).forEach(([key, value]) => {
      if (data[0][key.toLowerCase()] && !value.toLowerCase().includes('all')) {
        data = data.filter(item => item[key.toLowerCase()]?.toLowerCase() === value.toLowerCase());
      }
    });

    return data;
  };

  const fetchData = async (period) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/treemap/period:${period}`);
      const timestamp = new Date().getTime();
      setTreeMapData(prevState => ({
        ...prevState,
        [period]: { data: response.data, timestamp }
      }));
      setDisplayedTimestamp(moment(timestamp).format('LLL'));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAnimationData = async (period) => {
    try {
      setIsPlayLoading(true);
      const response = await axiosInstance.get(`/treemap/animate/period:${period}`);
      setTreeMapAnimationData(prevState => ({
        ...prevState,
        [period]: response.data
      }));
      animateTreeMap(response.data);
    } catch (error) {
      console.error("Error fetching animation data:", error);
    } finally {
      setIsPlayLoading(false);
    }
  };

  const handleSelectPeriod = (period) => {
    setSelectedPeriod(period);
    const currentTime = new Date().getTime();
    const periodData = treeMapData[period];
    if (!periodData || (currentTime - periodData.timestamp) >= 15 * 60 * 1000) {
      fetchData(period);
    } else {
      const filtered = filterData(periodData.data, filters);
      setFilteredData(filtered);
      setDisplayedTimestamp(moment(periodData.timestamp).format('LLL'));
    }
  };

  const animateTreeMap = async (animationData) => {
    setIsPlaying(true);
    animationRef.current = true;
    for (let i = 0; i < animationData.length; i++) {
      if (!animationRef.current) break;
      const filtered = filterData(animationData[i].data, filters);
      setFilteredData(filtered);
      setDisplayedTimestamp(moment(animationData[i].timestamp).format('LLL'));
      await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust the timeout as needed
    }
    setIsPlaying(false);
  };

  const handlePlay = () => {
    if (isPlaying) return;
    const periodData = treeMapData[selectedPeriod];
    originalStateRef.current = {
      filteredData: filteredData,
      timestamp: displayedTimestamp
    };
    if (!treeMapAnimationData[selectedPeriod]) {
      fetchAnimationData(selectedPeriod);
    } else {
      animateTreeMap(treeMapAnimationData[selectedPeriod]);
    }
  };

  const handleStop = () => {
    animationRef.current = false;
    setIsPlaying(false);
    const { filteredData, timestamp } = originalStateRef.current;
    setFilteredData(filteredData);
    setDisplayedTimestamp(timestamp);
  };

  useEffect(() => {
    handleSelectPeriod('Daily');
  }, []);

  useEffect(() => {
    const periodData = treeMapData[selectedPeriod];
    if (periodData) {
      const filtered = filterData(periodData.data, filters);
      setFilteredData(filtered);
      setDisplayedTimestamp(moment(periodData.timestamp).format('LLL'));
    }
  }, [treeMapData, filters, selectedPeriod]);

  if (loading) {
    return <Loading />;
  }

  if (!filteredData.length) {
    return <div>Please change selections to view the market map.</div>;
  }

  return (
    <div className="treemap-container">
      <TreeMapHeader timestamp={displayedTimestamp} />
      <TreeMapDetail data={filteredData} />
      <TreeMapButtons
        selectedPeriod={selectedPeriod}
        onSelectPeriod={handleSelectPeriod}
        onPlay={handlePlay}
        onStop={handleStop}
        isPlayBusy={isPlayLoading || isPlaying}
      />
      <TreeMapFooter filters={filters} />
    </div>
  );
}

export default TreeMap;
