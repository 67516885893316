import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { axiosInstanceWithPolling } from '../../axios';
import Loading from '../Loading/Loading';
import './CompanyNews.css';

const CompanyNews = ({ companyName, ticker, onLoadingChange }) => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        onLoadingChange(loading);
    }, [loading]);

    useEffect(() => {
        const fetchCompanyNews = async () => {
            try {
                const response = await axiosInstanceWithPolling('/news/company', {
                    params: { name: companyName, ticker: ticker }
                });
                setNews(response.data);
            } catch (error) {
                console.error('Error fetching company news:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCompanyNews();
    }, [companyName, ticker]);

    if (loading) return <Loading />;

    let lastDate = null;

    return (
        <div className="company-news-container">
            <div className='root-box'>
                {news.map((item, index) => {
                    const currentDate = moment(item.published_at).format('MMM D, YYYY');
                    const showDate = currentDate !== lastDate;
                    lastDate = currentDate;

                    return (
                        <div key={index} className="company-news-item">
                            <div className={`company-news-date ${showDate ? '' : 'hidden-date'}`}>
                                {currentDate}
                            </div>
                            <div className="company-news-hour">
                                {moment(item.published_at).format('HH:mm')}
                            </div>
                            <a href={item.url} className="company-news-title" target="_blank" rel="noopener noreferrer">
                                {item.title}
                            </a>
                            <div className="company-news-source">
                                {item.source}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CompanyNews;