// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PriceFooter.css */
.price-footer {
    display: flex;
    justify-content: center; /* Center the buttons */
    align-items: center;
    padding: 1rem;
    color: #fff;
}

.price-footer button {
    color: #000;
    border: none;
    margin: 1px;
    border-radius: 10px; /* Make the borders round */
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.price-footer button:hover {
    background-color: var(--light-blue-color);
}

.price-footer button.active {
    background-color: var(--dark-blue-color);
    color: #fff;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/Price/PriceFooter.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,aAAa;IACb,uBAAuB,EAAE,uBAAuB;IAChD,mBAAmB;IACnB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;IACX,mBAAmB,EAAE,2BAA2B;IAChD,oBAAoB;IACpB,eAAe;IACf,uDAAuD;AAC3D;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,wCAAwC;IACxC,WAAW;IACX,iBAAiB;AACrB","sourcesContent":["/* PriceFooter.css */\n.price-footer {\n    display: flex;\n    justify-content: center; /* Center the buttons */\n    align-items: center;\n    padding: 1rem;\n    color: #fff;\n}\n\n.price-footer button {\n    color: #000;\n    border: none;\n    margin: 1px;\n    border-radius: 10px; /* Make the borders round */\n    padding: 0.5rem 1rem;\n    cursor: pointer;\n    transition: background-color 0.3s ease, color 0.3s ease;\n}\n\n.price-footer button:hover {\n    background-color: var(--light-blue-color);\n}\n\n.price-footer button.active {\n    background-color: var(--dark-blue-color);\n    color: #fff;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
