// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
}

.article-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #2c3e50;
}

.article-intro {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.section-title {
    font-size: 1.5em;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #34495e;
}

.section-list {
    list-style-type: none;
    padding-left: 0;
}

.section-list li {
    margin-bottom: 10px;
}

.section-list li strong {
    font-weight: bold;
}

.section-list ul {
    list-style-type: disc;
    padding-left: 20px;
}

.article-conclusion {
    font-size: 1.2em;
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/AI/AI.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".article-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    font-family: Arial, sans-serif;\n    line-height: 1.6;\n    color: #333;\n}\n\n.article-title {\n    font-size: 2em;\n    margin-bottom: 20px;\n    color: #2c3e50;\n}\n\n.article-intro {\n    font-size: 1.2em;\n    margin-bottom: 20px;\n}\n\n.section-title {\n    font-size: 1.5em;\n    margin-top: 30px;\n    margin-bottom: 10px;\n    color: #34495e;\n}\n\n.section-list {\n    list-style-type: none;\n    padding-left: 0;\n}\n\n.section-list li {\n    margin-bottom: 10px;\n}\n\n.section-list li strong {\n    font-weight: bold;\n}\n\n.section-list ul {\n    list-style-type: disc;\n    padding-left: 20px;\n}\n\n.article-conclusion {\n    font-size: 1.2em;\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
